import React, { useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import { useDebounce } from "use-debounce";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { Buffer } from "buffer";
import { icoAddress, supportedChain } from "../../utils/constant";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { zeroAddress } from "viem";
import { useLocation } from "react-router-dom";
import WertWidget from "@wert-io/widget-initializer";
import ClipboardJS from "clipboard"; // Import ClipboardJS for clipboard operations
const { ethereum } = window;
window.Buffer = Buffer;

const Buy = ({
  selectedToken,
  setStep,
  setReceipt,
  setIsWhiteListed,
  round,
  icoContract,
  usdtContract,
  setOpenSuccess,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const { isConnected, address, chainId } = useAccount();
  const [quotation, setQuotation] = useState(0);
  const location = useLocation(); // Get the current location

  const referralLinkInputRef = useRef(null); // Reference for the referral link input

  // Setup ClipboardJS for copying referral links to clipboard
  useEffect(() => {
    const clipboard = new ClipboardJS(".copy-button", {
      target: () => referralLinkInputRef.current, // Target the input field
    });

    clipboard.on("success", () => {
      toast.success("Link Copied to Clipboard"); // Show success message on copy
    });

    clipboard.on("error", () => {
      toast.error("Failed to Copy Link"); // Show error message on copy failure
    });

    // Cleanup function to destroy the ClipboardJS instance
    return () => {
      clipboard.destroy();
    };
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const buyTokens = async (amount, tokenType) => {
    try {
      setStep(1);
      const queryParams = new URLSearchParams(location.search); // Parse the query string
      let referralCode = queryParams.get("referralcode") ?? zeroAddress; // Get the referral code
      if (ethereum === undefined) {
        throw new Error("Wallet Not Found");
      }
      console.log(chainId !== supportedChain);
      if (chainId !== supportedChain) {
        console.log(chainId);
        throw new Error("Switch to Mainnet");
      }
      let tx;
      if (tokenType === zeroAddress) {
        tx = await icoContract.buyToken(address, 0, tokenType, referralCode, {
          value: ethers.utils.parseEther(amount),
        });
      } else {
        await approveTokens(amount);

        tx = await icoContract.buyToken(
          address,
          ethers.utils.parseUnits(amount, "6"),
          tokenType,
          referralCode
        );
      }
      const receipt = await tx.wait();
      setReceipt(receipt.transactionHash);
      toast.success("Transaction Successful");
      setInputValue("");
      setStep(2);
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
      setStep(3);
      console.log(e);
    }
  };

  function truncateAmount(value) {
    const parts = value.split(".");
    if (parts.length === 2 && parts[1].length > 5) {
      return Number(`${parts[0]}.${parts[1].substring(0, 6)}`);
    }
    return Number(value); // Return the original number if no truncation is needed
  }

  const buyWithCard = async (amount) => {
    try {
      setStep(1);
      const queryParams = new URLSearchParams(location.search); // Parse the query string
      let referralCode = queryParams.get("referralcode") ?? zeroAddress; // Get the referral code
      if (ethereum === undefined) {
        throw new Error("Wallet Not Found");
      }

      if (chainId !== supportedChain) {
        throw new Error("Switch to Mainnet");
      }
      let ABI = ["function buyToken(address,uint256,address,address)"];
      let iface = new ethers.utils.Interface(ABI);
      const sc_input_data = iface.encodeFunctionData("buyToken", [
        address,
        0,
        zeroAddress,
        referralCode,
      ]);

      const amountConversion = await icoContract.getETHAmount(
        ethers.utils.parseUnits(String(amount), "6")
      );
      const amountToBeSend = truncateAmount(
        ethers.utils.formatEther(amountConversion)
      );
      if (amountToBeSend === 0) {
        throw new Error("Invalid amount provided");
      }

      const signedData = signSmartContractData(
        {
          address: address,
          commodity: "ETH",
          commodity_amount: amountToBeSend,
          network: "ethereum",
          sc_address: icoAddress,
          sc_input_data,
        },
        process.env.REACT_APP_WERT_PRIVATE_KEY
      );

      const icoOptions = {
        item_info: {
          author: "Qorra",
          author_image_url:
            "https://qorra.io/wp-content/uploads/2024/06/QORRA571x571.jpg",
          image_url: "https://partner-sandbox.wert.io/sample_nft.png",
          name: "Qorra App",
          category: "Buy Token",
        },
      };
      const otherWidgetOptions = {
        partner_id: process.env.REACT_APP_WERT_PARTNER_ID,
        origin: process.env.REACT_APP_WERT_BASE_URL,
        extra: icoOptions,
      };
      let _data;
      const wertWidget = new WertWidget({
        ...signedData,
        ...otherWidgetOptions,
        listeners: {
          "payment-status": (data) => {
            _data = data.status;
            if (data.status === "success") {
              setReceipt(data.tx_id);
              toast.success("Transaction Successful");
              setInputValue("");
              setStep(2);
            } else if (
              data.status === "failed" ||
              data.status === "failover" ||
              data.status === "canceled"
            ) {
              setStep(3);
              console.log(data.status);
            }
            console.log("Payment status:", data.status);
          },
          close: () => {
            console.log(_data, "close");
            if (_data !== "success") {
              setStep(3);
            }
            console.log("Closed");
          },
        },
      });
      wertWidget.open();
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
      setStep(3);
      console.log(e);
    }
  };

  const approveTokens = async (amount) => {
    const tx = await usdtContract.approve(
      icoAddress,
      ethers.utils.parseUnits(amount, "6")
    );
    const receipt = await tx.wait();
    setReceipt(receipt.transactionHash);
    toast.success("USDT Approved to ICO");
  };

  const getQuotation = async (amount, tokenType) => {
    try {
      const quotation = await icoContract.getQuote(
        ethers.utils.parseUnits(
          amount.toString(),
          tokenType === zeroAddress ? "18" : "6"
        ),
        tokenType
      );
      return ethers.utils.formatEther(quotation);
    } catch (err) {
      console.log(err);
    }
  };

  const checkWhiteList = async () => {
    try {
      const res = await icoContract.isUserWhitelist(address);
      setIsWhiteListed(res === true ? "true" : "false");
      setOpenSuccess(res);
    } catch (err) {
      console.log(err);
    }
  };

  // Function to copy referral link to clipboard
  const copyText = () => {
    navigator.clipboard.writeText(
      address && `${process.env.REACT_APP_BASE_URL}?referralcode=${address}`
    );
    // toast.success("Link Copied to Clipboard"); // Show success message on copy
  };

  useEffect(() => {
    if (isConnected && round === "1") {
      checkWhiteList();
    }
  }, [address, round]);

  useEffect(() => {
    const fetchQuote = async () => {
      if (debouncedInputValue) {
        const res = await getQuotation(
          debouncedInputValue,
          selectedToken.address.toLowerCase()
        );
        setQuotation(res);
      } else {
        setQuotation("");
      }
    };

    fetchQuote();
  }, [debouncedInputValue, selectedToken.address]);

  return (
    <div className="">
      <div className="flex items-center gap-3">
        <div className="flex flex-1 flex-col gap-1">
          <label className="text-white text-[14px] md:text-[18px]">
            Pay With {selectedToken.label}
          </label>
          <div className="flex items-center gap-2 bg-white rounded-md p-2">
            <input
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="0"
              className="cleanbtn w-full h-[35px] md:h-[42px] text-[14px] md:text-[18px]"
              disabled={round === "0"}
            />
            <img
              src={selectedToken.icon}
              className="h-6 w-6 object-contain"
              alt="icon"
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <label className="text-white text-[14px] md:text-[18px]">
            Qorra Amount
          </label>
          <div className="flex items-center gap-2 bg-white rounded-md p-2">
            <input
              type="number"
              value={quotation}
              placeholder="0"
              className="cleanbtn w-full h-[35px] md:h-[42px] text-[14px] md:text-[18px]"
            />
            <img
              src="./images/qorIcon.svg"
              className="h-6 w-6 object-contain"
              alt="icon"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-7 gap-4">
        {selectedToken.catagory === "card" ? (
          <button
            onClick={() => {
              buyWithCard(Number(inputValue));
            }}
            disabled={inputValue === "" || Number(inputValue) === 0}
            className="btn bg-themeColor2 text-[#161819] font-semibold text-base h-[52px] disabled:cursor-not-allowed md:h-[60px]"
          >
            Buy With Card
          </button>
        ) : (
          <button
            onClick={() => {
              buyTokens(inputValue, selectedToken.address.toLowerCase());
            }}
            disabled={inputValue === "" || Number(inputValue) === 0}
            className="btn bg-themeColor2 text-[#161819] font-semibold text-base h-[52px] disabled:cursor-not-allowed md:h-[60px]"
          >
            Buy Now
          </button>
        )}
        {/* Referral code input field */}
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex items-center gap-2 bg-white rounded-md p-2">
            <input
              type="text"
              placeholder="Referral Code"
              value={
                address &&
                `${process.env.REACT_APP_BASE_URL}?referralcode=${address}`
              }
              className="cleanbtn w-full h-[35px] md:h/[42px] text-[14px] md:text-[18px]"
              ref={referralLinkInputRef} // Attach the ref here
              readonly
            />
            <img
              src="./images/refcodeIcon.svg"
              className="h-6 w-6 object-contain cursor-pointer copy-button" // Add the copy-button class
              alt="icon"
              onClick={copyText} // Optionally keep onClick if you want to support direct click action as well
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
